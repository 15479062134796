////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(250, 183, 76);
}
.bg-thirdColor {
  background-color: rgb(123, 124, 128);
}
.bg-fourthColor {
  background-color: rgb(255, 255, 255);
}
.bg-fifthColor {
  background-color: rgb(232, 232, 232);
}
.bg-sixthColor {
  background-color: rgb(17, 17, 17);
}
.bg-seventhColor {
  background-color: rgb(232, 232, 232);
}
